/*
This CSS has been created based on Classless.de framework.
Some of the Classless classes has been customized slightly to fit design needed for Pablo, but overall 90% stayed original.
It also contains Custom classes at the very bottom of the page.
*/

/*
Classless.css v1.0

Table of Contents:
 1. Theme Settings
 2. Reset
 3. Base Style
 4. Extras  (remove unwanted)
 5. Classes  (remove unwanted)
 6. Pablo Custom classes
*/

/* 1. Theme Settings ––––––––––––––––––––-–––––––––––––– */

:root,
html[data-theme="light"] {
  --rem: 12pt;
  --width: 60rem;
  --navpos: fixed; /* fixed | absolute */
  --font-p: 400 1em/1.7 "Inter var", "ui-sans-serif", "system-ui";
  --font-h: 700 0.9em/1.5 "Inter var", "ui-sans-serif", "system-ui";
  --font-c: 0.9em/1.4 "DejaVu Sans Mono", monospace;
  --border: 1px solid var(--cmed);
  --ornament: "‹‹‹ ›››";

  --cfg: rgb(17 24 39 / 1);
  --cbg: #fff;
  --cdark: #888;
  --clight: #f5f6f7;
  --cmed: rgb(229 231 235);
  --clink: #07c;
  --cemph: #088;
  --cemphbg: #0881;
  --cred: #e221;
  --cgreen: #e8f3ff;

  --cnavbg: rgb(31 41 55);
  --cnavtext: rgb(209 213 219);
  --cnavtexthover: var(--cbg);
  --cnavtextfocus: var(--cnavtexthover);
  --cnavbuttonbg: var(--cnavbg);
  --cnavbuttonbghover: rgb(55 65 81);
  --cnavbuttonbgfocus: rgb(16 24 39);
}

/* 2. Reset –––––––––––––––––––––––––––––––––––––––––––– */

/* reset block elements  */

* {
  box-sizing: border-box;
  border-spacing: 0;
  margin: 0;
  padding: 0;
}

header,
footer,
figure,
video,
details,
blockquote,
ul,
ol,
dl,
fieldset,
pre,
pre > code,
caption {
  display: block;
  margin: 0.5rem 0rem 1rem;
  width: 100%;
  overflow: auto hidden;
  text-align: left;
}

video,
summary,
input,
select {
  outline: none;
}

/* reset clickable things  (FF Bug: select:hover prevents usage) */

a,
button,
select,
summary {
  color: var(--clink);
  cursor: pointer;
  user-select: none;
}

/* 3. Base Style ––––––––––––––––––––––––––––––––––––––– */

html {
  font-size: var(--rem);
  background: var(--cbg);
}

body {
  position: relative;
  margin: auto;
  max-width: var(--width);
  font: var(--font-p);
  color: var(--cfg);
  padding: 2.4rem 0.6rem 0;
  overflow-x: hidden;
}

body > footer {
  margin: 10rem 0rem 0rem;
  font-size: 90%;
}

p {
  margin: 0.6em 0;
}

/* links */

a[href] {
  text-decoration: underline solid var(--cmed);
  text-underline-position: under;
}

a[href^="#"] {
  text-decoration: none;
}

a:hover,
button:not([disabled]):hover,
summary:hover {
  filter: brightness(92%);
  color: var(--cemph);
  border-color: var(--cemph);
}

/* lists */

ul,
ol,
dl {
  margin: 1rem 0;
  padding: 0 0 0 2em;
}

li:not(:last-child),
dd:not(:last-child) {
  margin-bottom: 0.5rem;
}

dt {
  font-weight: bold;
}

/* headings */

h1,
h2,
h3,
h4,
h5 {
  margin: 1.5em 0 0.5rem;
  font: var(--font-h);
  line-height: 1.2em;
  clear: both;
}

h1 + h2,
h2 + h3,
h3 + h4,
h4 + h5 {
  margin-top: 0.5em;
  padding-top: 0;
}

/* non-clashing headings */

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 1.1em;
}

h5 {
  font-size: 1.2em;
  color: var(--cfg);
}

h6 {
  font-size: 1em;
  font-style: italic;
  display: inline;
}

h6 + p {
  display: inline;
}

/* tables */

table {
  font-style: normal;
  width: 100%;
  table-layout: fixed;
  padding: 0rem 0rem 0.7rem 0;
  /*font-size: 90%;*/
}

td,
th {
  text-align: left;
  border-bottom: 0.1rem solid var(--cmed);
  padding: 0.1rem 0.5rem 0.1rem 0;
}

td {
  vertical-align: center;
  font-size: 95%;
}

th {
  vertical-align: bottom;
}

td:has(input),
td:has(select),
td:has(.card),
th:has(input) {
  padding: 0.1rem 0.2rem 0.1rem 0;
}

td > .card {
  margin: 0rem 0 0.15rem 0;
}

tbody tr:hover {
  background-color: var(--clight);
}

.trnohover:hover {
  background-color: var(--cbg);
}

tfoot > tr > td {
  border-bottom: none;
  font-style: italic;
  min-width: 5em;
}

.alcenter {
  text-align: center;
  background-color: #0077cc;
  horiz-align: center;
  padding-right: 10rem;
}

/* Auto Numbering: figure/tables/headings/cite */

article {
  counter-reset: h2 0 h3 0 tab 0 fig 0 lst 0 ref 0 eq 0;
}

article figure figcaption:before {
  color: var(--cemph);
  counter-increment: fig;
  content: "Figure " counter(fig) ": ";
}

/* subfigures */

figure {
  counter-reset: subfig 0;
}

article figure figure {
  counter-reset: none;
}

article figure > figure {
  display: inline-grid;
  width: auto;
}

figure > figure:not(:last-of-type) {
  padding-right: 1rem;
}

article figure figure figcaption:before {
  counter-increment: subfig 1;
  content: counter(subfig, lower-alpha) ": ";
}

/* listings */

article figure pre + figcaption:before {
  counter-increment: lst 1;
  content: "Listing " counter(lst) ": ";
}

/* tables */

figure > table:only-of-type {
  display: table;
  margin: 0.5em auto !important;
  width: fit-content;
}

article figure > table caption {
  display: table-caption;
  caption-side: bottom;
}

article figure > table + figcaption:before,
article table caption:before {
  color: var(--cemph);
  counter-increment: tab 1;
  content: "Table " counter(tab) ": ";
}

/* headings */

article h2,
h3 {
  position: relative;
}

article h2:before,
article h3:before {
  display: inline-block;
  position: relative;
  font-size: 0.6em;
  text-align: right;
  vertical-align: baseline;
  left: -1rem;
  width: 2.5em;
  margin-left: -2.5em;
}

article h1 {
  counter-set: h2;
}

article h2:before {
  counter-increment: h2;
  content: counter(h2) ". ";
  counter-set: h3;
}

article h3:before {
  counter-increment: h3;
  content: counter(h2) "." counter(h3) ". ";
}

@media (max-width: 60rem) {
  h2:before,
  h3:before {
    display: none;
  }
}

/* tooltip + citation */

article p > cite:before {
  padding: 0 0.5em 0 0;
  counter-increment: ref;
  content: " [" counter(ref) "] ";
  vertical-align: super;
  font-size: 0.6em;
}

article p > cite > *:only-child {
  display: none;
}

article p > cite:hover > *:only-child,
[data-tooltip]:hover:before {
  display: inline-block;
  z-index: 40;
  white-space: pre-wrap;
  position: absolute;
  left: 1rem;
  right: 1rem;
  padding: 1em 2em;
  text-align: center;
  transform: translateY(calc(-100%));
  content: attr(data-tooltip);
  color: var(--cbg);
  background-color: var(--cemph);
  box-shadow: 0 2px 10px 0 black;
}

[data-tooltip],
article p > cite:before {
  color: var(--clink);
  border: 0.8rem solid transparent;
  margin: -0.8rem;
}

/* misc */

blockquote {
  border-left: 0.4rem solid var(--cmed);
  padding: 0 0 0 1rem;
}

time {
  color: var(--cdark);
}

hr {
  border: 0;
  border-top: 0.1rem solid var(--cmed);
}

nav {
  width: 100%;
  background-color: var(--cnavbg);
}

::selection,
mark {
  background: var(--clink);
  color: var(--cbg);
}

/* 4. Extra Style –––––––––––––––––––––––––––––––––––––– */

/* navbar */

nav + * {
  margin-top: 3rem;
}

body > nav,
header nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 41;
  box-shadow: 0vw -50vw 0 50vw var(--cnavbg);
}

nav ul {
  list-style-type: none;
}

nav ul:first-child {
  margin: 0;
  padding: 0;
  overflow: visible;
}

nav ul:first-child > li {
  display: inline-block;
  vertical-align: middle;
  margin: 0;
  padding: 0.5rem 0 0.4rem 0.6rem;
}

nav ul > li > ul {
  display: none;
  width: auto;
  position: absolute;
  margin: 0.5rem 0;
  padding: 1rem 2rem;
  background-color: var(--clight);
  border: var(--border);
  border-radius: 4px;
  z-index: 42;
}

nav ul > li > ul > li {
  white-space: nowrap;
}

nav ul > li:hover > ul {
  display: block;
}

@media (max-width: 43rem) {
  nav ul:first-child > li:first-child:after {
    content: " \25BE";
  }

  nav ul:first-child > li:not(:first-child):not(.sticky) {
    display: none;
  }

  nav ul:first-child:hover > li:not(:first-child):not(.sticky) {
    display: block;
    float: none !important;
  }
}

/* details/cards */

summary > * {
  display: inline;
}

.card,
details {
  display: block;
  margin: 0.5rem 0rem 1rem;
  border-radius: 4px;
  overflow: hidden;
}

.box-luminescent {
  box-shadow: 0 0 5px var(--clink);
}

.card > img:first-child {
  margin: -3px -0.6rem;
  max-width: calc(100% + 1.2rem);
}

.hint {
  background-color: var(--cemphbg);
}

.warn {
  background-color: var(--cred);
}

/* forms and inputs  */

textarea,
input:not([type="range"]):not([type="file"]),
button,
select {
  font: var(--font-p);
  color: var(--cfg);
  border-radius: 4px;
  border: 1.5px solid var(--cmed);
  background-color: var(--cbg);
}

textarea {
  resize: vertical;
  width: 100%;
  height: 100%;
}

input:not([type="checkbox"]),
select {
  padding: 0.05rem 0 0 0.4rem;
  min-width: 4rem;
  height: 1.6rem;
}

input::placeholder {
  font-weight: normal;
  font-style: normal;
  opacity: 0.5;
  color: var(--cfg);
}

select,
input:not([type="checkbox"]):not([type="radio"]) {
  display: block;
  width: 100%;
  margin: 0 0 0 0rem;
}

button {
  font-weight: bold;
  background-color: var(--clight);
  margin: 0.5em;
  border: 1.5px solid var(--clink);
}

button {
  padding: 0.4em 1em;
  font-size: 85%;
  letter-spacing: 0.1em;
}

button[disabled] {
  color: var(--cdark);
  border-color: var(--cmed);
}

fieldset {
  border-radius: 4px;
  border: var(--border);
  padding: 0.5em 1em;
}

textarea:hover,
input:not([type="checkbox"]):not([type*="ra"]):not([type="file"]):hover,
select:hover {
  border: 1.5px solid var(--cemph);
}

textarea:focus,
input:not([type="checkbox"]):not([type*="ra"]):focus {
  border: 1.5px solid var(--clink);
  box-shadow: 0 0 5px var(--clink);
}

p > button {
  padding: 0 0.5em;
  margin: 0 0.5em;
}

p > select {
  padding: 0;
  margin: 0 0.5em;
}

/* 5. Bootstrap-compatible classes ––––––––––––––––––––– */

/* grid */

.row {
  display: flex;
  margin: 0rem -0.6rem;
  align-items: stretch;
}

.row [class*="col"] {
  padding: 0.1rem 0.6rem;
}

.row .col {
  flex: 1 1 100%;
}

.row .col-2 {
  flex: 0 0 16.66%;
  max-width: 16.66%;
}

.row .col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.row .col-4 {
  flex: 0 0 33.33%;
  max-width: 33.33%;
}

.row .col-5 {
  flex: 0 0 41.66%;
  max-width: 41.66%;
}

.row .col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.row .col-55 {
  flex: 0 0 55%;
  max-width: 55%;
}

@media (max-width: 40rem) {
  .row {
    flex-direction: column;
  }
}

/* align */

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.clearfix {
  clear: both;
}

/* colors */

.text-black {
  color: #000;
}

.text-white {
  color: #fff;
}

.text-primary {
  color: var(--cemph);
}

.text-secondary {
  color: var(--cdark);
}

.bg-white {
  background-color: #fff;
}

.bg-light {
  background-color: var(--clight);
}

.bg-primary {
  background-color: var(--cemph);
}

.bg-secondary {
  background-color: var(--cmed);
}

/* margins */

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.m-0 {
  margin: 0 !important;
}

.m-1,
.mx-1,
.mr-1 {
  margin-right: 1rem !important;
}

.m-1,
.mx-1,
.ml-1 {
  margin-left: 1rem !important;
}

.m-1,
.my-1,
.mt-1 {
  margin-top: 1rem !important;
}

.m-1,
.my-1,
.mb-1 {
  margin-bottom: 1rem !important;
}

/* padding */

.p-0 {
  padding: 0 !important;
}

.p-1,
.px-1,
.pr-1 {
  padding-right: 1rem !important;
}

.p-1,
.px-1,
.pl-1 {
  padding-left: 1rem !important;
}

.p-1,
.py-1,
.pt-1 {
  padding-top: 1rem !important;
}

.p-1,
.py-1,
.pb-1 {
  padding-bottom: 1rem !important;
}

/* 6. Pablo custom classes ––––––––––––––––––––– */

.nav img {
  padding-top: 0.3em;
  height: 2.6em;
}

.nav-button {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--cnavtext);
  background-color: var(--cnavbuttonbg);
  border-radius: 0.375rem;
  padding: 0.5rem 0.75rem;
}

.nav-button img {
  padding-right: 0.4rem;
  height: 1rem;
  vertical-align: middle;
}

a[class*="nav-button"] {
  text-decoration: none;
}

.nav-button:hover {
  color: var(--cnavtexthover);
  background-color: var(--cnavbuttonbghover);
}

.subheader {
  margin: 0em 0 0em 0;
  background-color: var(--cbg);
  box-shadow:
    0vw -50vw 0 50vw var(--cbg),
    0 calc(-50vw + 2px) 4px 50vw var(--cmed);
}

.subheader h1 {
  margin: 0.7em 0 0.7em 0;
}

table h2,
table h3,
table h4 {
  margin: 0em 0 0.3em 0;
}

.config-table {
  width: 100%;
}

.config-table td:first-child,
.config-table th:first-child {
  width: 48%;
}

.config-table-double td:nth-child(-n + 2),
.config-table-double th:nth-child(-n + 2) {
  width: 24%;
}

.config-table-triple td:nth-child(-n + 3),
.config-table-triple th:nth-child(-n + 3) {
  width: 16%;
}

.table-with-button td:last-child,
.table-with-button th:last-child {
  width: 1em;
}

.align-top {
  vertical-align: top;
}

.column-hide-overflow {
  overflow: hidden;
  white-space: nowrap;
}

.table-scrollable {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

.textarea-nowrap {
  white-space: pre;
  overflow-wrap: normal;
  overflow-x: scroll;
}

.gray,
.gray:hover {
  color: var(--cdark);
  pointer-events: none;
}

.inline {
  display: flex;
}

.inline .max-width {
  width: 100%;
}
